<template>
  <div class="content">
    <Header />
    <div class="info">
      <div class="order flex fWrap fjcS">
        <div class="back">
          <a href="#!" @click.prevent="this.$router.go(-1)"
            >Вернуться к списку</a
          >
        </div>
        <div class="info">
          <div class="row">
            <span
              >Номер заказа:
              <strong>{{ orderId > 0 ? orderId : "Новый" }}</strong></span
            >
          </div>
          <div class="row flex">
            <span
              >Дата: <strong>{{ orderData }}</strong></span
            >
            <span
              >Время: <strong>{{ orderTime }}</strong></span
            >
          </div>
          <div class="row ui" :class="{ telega }">
            <span>Телефон </span>
            <input
              type="text"
              :readonly="orderId > 0"
              :disabled="orderId > 0"
              v-model="userPhone"
              @keyup="parsePhoenix"
              @blur="searchUser"
            />
          </div>
          <div class="row ui">
            <span>Имя </span>
            <input
              type="text"
              :disabled="orderId > 0"
              :readonly="orderId > 0"
              v-model="userName"
            />
          </div>

          <div class="row ui">
            <span style="font-weight: 700;">{{userRating}}</span>
          </div>
          
          <div class="row ui">
            <span>Доставка:</span>
            <textarea type="text"
              :disabled="orderId > 0"
              :readonly="orderId > 0"
              v-model="userDelivery"></textarea>
          
          </div>
          <div class="row ui" v-if="Object.keys(payment).length !== 0">
            <span>Оплата по карте:</span>
            <div class="mt5"><strong>Статус: {{this.payment.status==0?'Оплачено':'Оплата не прошла'}}</strong></div>
            <div class="mt5"><strong>Сумма: {{this.payment.summa}} руб.</strong></div>
            <div class="mt5"><strong>Карта: {{this.payment.card}}</strong></div>
            <div class="mt5"><strong>Транзакция: {{this.payment.transactionId}}</strong></div>
            <div class="mt5"><a href="#!" @click.prevent="returnMoney">Сделать возврат</a></div>
          </div>
          <div class="ui">
            <span>Статус заказа:</span>
            <DropDown
              :list="statusList"
              :selected="orderStatus"
              :itemId="0"
              @dropSelect="showChange"
            />
          </div>
          <div class="ui martop15">
            <button class="btnPrint" @click.prevent="garModalOpen = true">
              Печать гарантийного талона
            </button>
          </div>
          <!-- <div class="ui martop15" v-if="1>2">
            <span>Трек номер для почты: (будет выслан по смс клиенту)</span>
            <input type="text" v-model="trackNum">
          </div>
          
           <div class="ui martop15">
            <button class="btnPrint" @click.prevent="checkModalOpen = true">
              Печать товарного чека
            </button>
          </div>
          <div class="ui martop15">
            <button class="btnPrint" @click.prevent="deliveryModalOpen = true">
              Печать карты доставки
            </button>
          </div> -->
          <div class="row ui">
            <a href="#!" class="btn" @click.prevent="addOrder"
              >Сохранить заказ</a
            >
          </div>
          <div class="row ui">
            <a href="#!" class="btnDel" @click.prevent="delOrder"
              >Удалить заказ</a
            >
          </div>
        </div>
        <div class="products">
          <h3>Товары в заказе</h3>
          <ul class="flex" :class="{'opacity50':item.status>0}" v-for="(item, key) in ordersProducts" :key="key">
            <li><input type="checkbox" class="isCheckPrint" :value="item.id" checked></li>
            <li class="editProduct"><a :data-id="key" @click.prevent="openEditProduct"> </a></li>
            <li class="dpr" v-if="item.isDpr">Товар в наличии в Донецке</li>
            <li class="elko" v-if="item.elko.elkoNum>0 && item.elko.elkoPrice>0">ELKO {{item.elko.elkoNum}} - {{item.elko.elkoPrice}}руб.</li>
            <li class="article">
              <a ><span>{{ item.article }}</span></a>
            </li>
            <li class="name">
              <span>{{ item.name }}</span>
            </li>
            <li class="cnt">
              <span>{{ item.cnt }} шт.</span>
            </li>
            <li class="price">
              <span>{{number_format(item.price * item.cnt, 0, ",", " ")}} руб.</span>
            </li>
            <li class="sailer" >
              <a :href="item.parentUrl" v-if="item.parentUrl!=''"><span>{{ item.saler }}</span></a
              >
            </li>
            <li class="del">
              <a
                href="#!"
                class="ico-del"
                :data-id="key"
                @click.prevent="del"
              ></a>
            </li>
          </ul>
          <div class="totalSumm">
            <span>Итого: {{ number_format(totalSumm, 0, ",", " ") }} руб.</span>
          </div>
          <div class="userComment">{{ userComment }}</div>
          <div class="managerCommentBlock ui">
            <span>Комментарий менеджера</span>
            <textarea v-model="managerComment" ></textarea>
          </div>
          <div class="addToOrderForm">
            <h3>Добавить в заказ</h3>
            <div class="art ui flex">
              <div class="row col50">
                <span>Артикул на сайте</span>
                <input type="text" v-model="searchArticle" />
              </div>
              <div class="row col40">
                <a href="#!" class="searchBtn" @click.prevent="searchProduct"
                  >Найти</a
                >
              </div>
            </div>
            <div class="plaha gray" v-if="searchPlahaisShow">
              <ul class="flex">
                <li>{{ searchList.name }}</li>
                <li><input type="text" v-model="cnt" /></li>
                <li>
                  <a
                    href="#!"
                    :data-id="searchList.id"
                    @click.prevent="addToOrderAuto"
                    >Добавить в заказ</a
                  >
                </li>
              </ul>
            </div>
          </div>


          <div class="addToOrderForm">
            <h3>Добавить в заказ</h3>

            <div class="plaha">
              <ul class="flex">
                <li><input type="text" v-model="addName" class="addName" placeholder="Название товара"></li>
                <li><input type="text" v-model="cnt" class="addCnt" placeholder="кол-во"/></li>
                <li><input type="text" v-model="addPrice" class="addPrice" placeholder="Цена"/></li>
                <li>
                  <a
                    href="#!"
                    :data-id="searchList.id"
                    @click.prevent="addToOrder"
                    >Добавить в заказ</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="garModalOverlay" :class="{ active: editProdModalOpen }">
    <div class="garModal">
      <h4>{{editProdName}}</h4>
      <a href="#!" class="close" @click.prevent="editProdModalOpen = false"></a>
      <div class="prodEdit">
        <div class="ui ">
            <span>Цена товара:</span>
            <input type="text" v-model="editProdPrice" />
        </div>
        <div class="ui ">
            <span>Кол-во:</span>
            <input type="text" v-model="editProdCnt" />
        </div>
        <div class="ui">
            <span>Статус заказа:</span>
            <DropDown
              :list="statusForProducts"
              :selected="productStatus"
              :itemId="0"
              @dropSelect="showChangeProduct"
            />
        </div>
      </div>
      <a href="#!" class="btn" :data-id="editProdId" @click.prevent="saveProduct">Сохранить товар</a>
    </div>
  </div>
  <div class="garModalOverlay" :class="{ active: checkModalOpen }">
    <div class="garModal" style="width: 450px;">
      <h4>Тип чека</h4>
      <a href="#!" class="close" @click.prevent="checkModalOpen = false"></a>
      <div class="prodEdit" style="display: block; margin: 20px 0">
        <div class="ui flex" style="justify-content: flex-start;">
            <input type="radio" name="pred" id="for100" v-model="checkType" value="100" checked>
            <label for="for100">Оплата 100%</label>
        </div>
        <div class="ui flex" style="justify-content: flex-start; width: 60%">
            <input type="radio" name="pred" v-model="checkType" value="150" id="for150">
            <label for="for150">Авансовый платеж 100%</label>
        </div>
        <div class="ui flex" style="justify-content: flex-start; width: 60%">
            <input type="radio" name="pred" v-model="checkType" value="50" id="for50">
            <label for="for50">Авансовый платеж 50%</label>
        </div>
        <div class="ui flex" style="justify-content: flex-start; width: 60%">
            <input type="radio" name="pred" v-model="checkType" value="40" id="for40">
            <label for="for40">Авансовый платеж 40%</label>
        </div>
        <div class="ui flex" style="justify-content: flex-start; width: 60%">
            <input type="radio" name="pred" v-model="checkType" value="30" id="for30">
            <label for="for30">Авансовый платеж 30%</label>
        </div>
        
       
      </div>
      <a href="#!" class="btnPrint" :data-id="editProdId" @click.prevent="printCheck">Печать</a>
    </div>
  </div>
  <div class="garModalOverlay" :class="{ active: deliveryModalOpen }">
    <div class="garModal">
      <h4>Карта заказа</h4>
      <a href="#!" class="close" @click.prevent="deliveryModalOpen = false"></a>
      <div class="adr">
        <span>Укажите точный адрес и время доставки</span>
        <input type="text" v-model="userDelivery" />
        <select v-model="userDeliveryTime">
          <option value="Первая половина дня">Первая половина дня</option>
          <option value="Вторая половина дня">Вторая половина дня</option>
        </select>
      </div>
      <a href="#!" class="btnPrint" @click.prevent="openCarta">Печать</a>
    </div>
  </div>
  <div class="garModalOverlay" :class="{ active: garModalOpen }">
    <div class="garModal">
      <h4>Гарантийный талон</h4>
      <a href="#!" class="close" @click.prevent="garModalOpen = false"></a>
      <div class="list">
        <form action="https://admin.tehnoland.shop/print/print.php" target="_blank" method="POST">
          <input type="hidden" name="printorder" :value="orderId" required>
          <input type="date" name="printdat" required>
          <input type="text" name="printgar" placeholder="Срок" required>          
          <button type="summit" class="btnPrint" @click="garModalOpen = false">Печать</button>
          <input type="checkbox" name="printfiz" value="1" id="printfiz" ><label for="printfiz">Без юр. лица</label>
        </form>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.order .products .addToOrderForm .plaha ul li:first-child {
    width: 50% !important;
}
.order .products .addToOrderForm .plaha ul li:nth-child(2) {
    width: 15% !important;
}
.order .products .addToOrderForm .plaha ul li:nth-child(3) {
    width: 20% !important;
}
.order .products .addToOrderForm .plaha.gray {
  background: #f9f9f9 !important;
}
.list form {
  input[type=date] {
    padding: 9px 8px;
    outline: none;
    width: 158px;
    border: 1px solid #501a98;
  }
  input[type=checkbox] {
    margin-left: 15px;
  }
  input[type=text] {
    padding: 10px 8px;
    outline: none;
    width: 58px;
    border: 1px solid #501a98;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.wrapper .ui input[type=radio] { 
    width: auto;
    margin: 0 8px 0 0;
}
.opacity50 {
  opacity: 0.3;
}
.addName {
  width: 100%;
}
.addPrice {
  width: 100%;
}
.addCnt {
  width: 100%;
}
.telega {
  position: relative;
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 5px;
    height: 5px;
    background: rgb(17, 183, 248);
    top: 38px;
    left: -8px;
  }
}
.mt5 {
  margin-top: 5px;
  color: #145614;
}
.prodEdit {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 20px;
  .ui {
    width: 33%;

  }
}
.editProduct {
  width: 5%;
    a {
      width: 15px;
      height: 15px;
      background: url(/img/ico-edit.svg) no-repeat center center;
      background-size: 15px;
      transition: 0.3s ease-out;
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        } 
    }  
}
.btnDel {
  text-align: center;
  color: gray !important;
  margin-top: 28px;
  transition: 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
}
.totalSumm {
  padding: 10px 0;
  span {
    padding-left: 66.3%;
    font-size: 16px;
    font-weight: 600;
  }
}
.order .products ul {
  position: relative;
  .dpr {
    position: absolute;
    font-size: 10px;
    background-color: #501a98;
    color: #f3f7ff;
    top: -13px;
    left: 0px;
    padding: 3px 8px;
    border-radius: 5px;
  }
  .elko {
    position: absolute;
    font-size: 10px;
    background-color: #0f5383;
    color: #f3f7ff;
    top: -13px;
    left: 156px;
    padding: 3px 8px;
    border-radius: 5px;
  }
}
.order .products .userComment {
  padding: 20px;
  background: #f3f7ff;
  font-weight: 500;
  border-radius: 10px;
  margin-top: 25px;
  display: block;
  height: auto;
  font-size: 14px;
}
.garModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: 0.5s ease-out;
  opacity: 0;
  display: none;
  background: rgba(#000000, 0.8);
  &.active {
    display: block;
    opacity: 1;
  }
  .garModal {
    position: absolute;
    top: 25%;
    width: 800px;
    padding: 30px;
    border-radius: 5px;
    background: white;
    left: 50%;
    transform: translate(-50%, -50%);
    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 15px;
      height: 15px;
      background: url(/img/ico-del.svg) no-repeat center center;
      background-size: 15px;
      transition: 0.3s ease-out;
      &:hover {
        opacity: 0.7;
      }
    }
    .adr {
      display: block;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px 0;
      input {
        border: 1px solid rgb(212, 212, 212);
        border-radius: 3px;
        padding: 5px 8px;
        text-align: left;
        font-size: 14px;
        outline: none;
        width: 70%;
      }
      select {
        border: 1px solid rgb(212, 212, 212);
        border-radius: 3px;
        padding: 5px 8px;
        text-align: left;
        font-size: 14px;
        outline: none;
        width: 25%;
        margin-left: 10px;
      }
      span {
        font-size: 14px;
        display: block;
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .list {
      margin: 25px 0 15px 0;
      .item {
        padding: 5px 0;
        border-bottom: 1px solid rgb(238, 238, 238);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        span {
          font-size: 14px;
          flex-grow: 2;
          width: 600px;
        }
        input {
          border: 1px solid rgb(212, 212, 212);
          border-radius: 3px;
          padding: 3px 7px;
          text-align: center;
          font-size: 14px;
          width: 70px;
          outline: none;
          margin: 0 20px;
          &.imei {
            width: 20%;
            margin: 0;
          }
        }
        a {
          width: 15px;
          height: 15px;
          opacity: 0.7;
          background: url(/img/ico-del.svg) no-repeat center center;
          background-size: 12px;
          display: block;
          transition: 0.3s ease-out;
          &:hover {
            opacity: 0.4;
          }
        }
      }
    }
    .btnPrint {
      width: fit-content;
    }
  }
}
.btnPrint {
  padding: 10px 10px 10px 34px;
  background: #f6f7fa url(/img/ico-print.svg) no-repeat center left;
  background-size: 15px;
  background-position-x: 10px;
  border: 1px solid #501a98;
  border-radius: 3px;
  font-family: Montserrat;
  transition: 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
.martop15 {
  margin-top: 15px;
}
input:disabled {
  font-weight: 700;
  border: none;
  padding: 0;
}
a.btn {
  color: white !important;
  text-align: center;
  margin-top: 50px;
}
.managerCommentBlock {
  margin-top: 25px;
  textarea {
    border: 1px solid gray;
    width: 100%;
    height: 55px;
    outline: none;
  }
}
.order .products ul li.name {
    width: 50%;
}
.row {
  textarea {
    background: #f3f7ff;
    border: none;
    width: 100%;
    font-size: 14px;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-family: Montserrat;
    height: 74px;
    font-weight: 600;
  }
}
</style>
<script>
import api from "@/components/app/api";
import Helper from "@/components/app/helper";
import DropDown from "@/components/app/dropDown";
import Config from "@/components/app/config";
import Header from "@/components/app/header";
import dateFormat from "dateformat";
export default {
  name: "Home",
  data() {
    return {
      searchArticle: "",
      trackNum: "",
      statusList: Config.statusList, //Список статусов
      statusForProducts: Config.statusForProducts, // Список статусов товаров внутри заказа
      searchList: {},
      searchPlahaisShow: false,
      ordersProducts: [],
      payment: {},
      ordersProductsGar: [],
      cnt: 1,
      printfiz: false,
      addName: "",
      addPrice: "",
      orderData: dateFormat(new Date(), "dd.mm.yyyy"),
      orderTime: dateFormat(new Date(), "hh:MM"),
      userPhone: "",
      userName: "",
      telega: false,
      userDelivery: "",
      managerComment: "",
      checkType: 100,
      userComment: "",
      userId: 0,
      userDeliveryTime: "",
      userRating: "",
      orderId: 0,
      orderStatus: 0,
      garModalOpen: false,
      checkModalOpen: false,
      deliveryModalOpen: false,
      editProdModalOpen: false,
      userProfile: {},
      editProdPrice: 0,
      editProdCnt: 0,
      editProdName: "",
      editProdIndex: 0,
      productStatus: 0,
      editProdId: 0
    };
  },
  watch: {
    garModalOpen(val) {
      if (val) {
        this.ordersProductsGar = [];
        this.ordersProducts.forEach((elem) => {
          elem.name = elem.name
            .replace("&#39;", "")
            .replace("#", "-")
            .replace("&", "-")
            .replace('"', "");
          this.ordersProductsGar.push(elem);
        });
      }
    },
  },
  computed: {
    totalSumm: function () {
      return this.ordersProducts.reduce(function (sum, currentAccount) {
        return sum + (currentAccount.status==0?currentAccount.price:0) * currentAccount.cnt;
      }, 0);
    },
  },
  methods: {

  async returnMoney() {
    if(confirm('Делаем возврат средств на карту клиента?')) {
        let response = await api.methods.ORDER_returnMoney({
          id: this.orderId,
          secret: "123123"
        });
        alert(response);
    }
  }, 
  async saveProduct() {
      let response = await api.methods.ORDER_save({
        id: this.editProdId,
        status: this.productStatus,
        cnt: this.editProdCnt,
        price: this.editProdPrice
      });

      this.editProdModalOpen = false
      
      Helper.methods.warning(response.data, "ok");

      this.ordersProducts[this.editProdIndex].price = this.editProdPrice
      this.ordersProducts[this.editProdIndex].cnt = this.editProdCnt
      this.ordersProducts[this.editProdIndex].name = this.editProdName
      this.ordersProducts[this.editProdIndex].status = this.productStatus


      if (this.$route.params.id) {
        await this.list();
      }
    },
    openEditProduct(e) {
      let ind = e.target.dataset.id
      this.editProdIndex = ind
      this.editProdPrice = this.ordersProducts[ind].price
      this.editProdCnt = this.ordersProducts[ind].cnt
      this.editProdName = this.ordersProducts[ind].name
      this.editProdId = this.ordersProducts[ind].id
      this.productStatus = this.ordersProducts[ind].status
      this.editProdModalOpen = true
    },
    changeGar(event) {
      let key = event.target.dataset.id;
      let value = event.target.value;
      this.ordersProductsGar[key].gar = value;
    },
    changeImei(event) {
      let key = event.target.dataset.id;
      let value = event.target.value;
      this.ordersProductsGar[key].imei = value;
    },
    openCarta(e) {
      e.target.style.display = "none";
      if (this.userDeliveryTime == "") {
        Helper.methods.warning("Не выбрали время доставки!", "error");
        e.target.style.display = "block";
        return;
      }

      let clearProducts = this.ordersProducts.map((elem) => {
        if(elem.status==0) {
          return elem
        }        
      })

      let request = {
        order: this.orderId,
        user: this.userName,
        time: this.userDeliveryTime,
        adress: this.userDelivery,
        phone: this.userPhone,
        products: clearProducts,
      };
      let jsonParams = JSON.stringify(request);
      window.open(
        `https://admin.tehnoland.shop/print/carta.php?params=${jsonParams}`,
        "_blank"
      );
      this.deliveryModalOpen = false;
      e.target.style.display = "block";
    },
    printCheck() {
      console.log(this.checkType, this.orderId);
      let arr =  document.querySelectorAll('.isCheckPrint:checked')
      let isPrintIds = []
      arr.forEach( elem => {
        isPrintIds.push(elem.value)
      })


      window.open(
        `https://admin.tehnoland.shop/print/check.php?checkType=${this.checkType}&order=${this.orderId}&isPrint=${isPrintIds.join(',')}`,
        "_blank"
      );
      this.checkModalOpen = false;
    },
    openReport() {
      let jsonParams = JSON.stringify(this.ordersProductsGar);
      console.log(this.ordersProductsGar);
      window.open(
        `https://admin.tehnoland.shop/print/garantiya.php?params=${jsonParams}&order=${this.orderId}`,
        "_blank"
      );
      this.garModalOpen = false;
    },
    deleteFromGarArr(elem) {
      let del = elem.target.dataset.del;
      if (this.ordersProductsGar.length > 1) {
        this.ordersProductsGar.splice(del, 1);
      } else {
        alert("Хотя бы один товар должен остаться в списке!");
        return;
      }
    },
    async searchUser() {
      if (Helper.methods.checkPhoenix(this.userPhone)) {
        this.userProfile = await api.methods.USERS_searchUser({
          phone: this.userPhone,
        });
        if (Helper.methods.isObject(this.userProfile)) {
          this.userName = this.userProfile.name;
          this.userDelivery = this.userProfile.delivery;
          this.userId = this.userProfile.id;
        }
      }
    },
    showChange(e) {
      this.orderStatus = e.id;
    },
    showChangeProduct(e) {
      this.productStatus = e.id;
    },
    parsePhoenix(e) {
      this.userPhone = Helper.methods.parsePhoenix(e, this.userPhone);
    },
    async addOrder() {
      
      if (Helper.methods.isEmptyStr(this.userName)) {
        Helper.methods.warning("Не заполнено поле имя", "error");
        return;
      }

      if (Helper.methods.isEmptyStr(this.userDelivery)) {
        Helper.methods.warning("Не заполнено поле доставка", "error");
        return;
      }

      if (this.ordersProducts.length == 0) {
        Helper.methods.warning("Не добавлены товары", "error");
        return;
      }

      let response = await api.methods.ORDER_add({
        name: this.userName,
        phone: this.userPhone,
        delivery: this.userDelivery,
        userId: this.userId,
        products: this.ordersProducts,
        status: this.orderStatus,
        track: this.trackNum,
        id: this.orderId,
        managerComment: this.managerComment,
      });

      this.userName = "";
      this.userPhone = "";
      this.managerComment = "";
      this.userDelivery = "";
      this.userId = 0;
      this.userProfile = {};
      this.ordersProducts = [];
      this.orderStatus = 0;
      this.orderId = 0;
      this.$router.go(-1);
      Helper.methods.warning(response, "ok");
    },

    async delOrder() {
      if (confirm("Удаляем заказ?")) {
        await api.methods.ORDER_delete({
          id: this.orderId,
        });
        this.$router.go(-1);
      }
    },

    number_format(number, decimals, dec_point, thousands_sep) {
      return Helper.methods.number_format(
        number,
        decimals,
        dec_point,
        thousands_sep
      );
    },

    del(e) {
      if (confirm("Удаляем товар?")) {
        let key = e.target.dataset.id;
        this.ordersProducts.splice(key, 1);
      }
    },
    addToOrderAuto(e) {
      let productId = Number(e.target.dataset.id);
      let isArrChanche = false;
      this.ordersProducts.forEach((e) => {
        if (e.pId == Number(productId)) {
          e.cnt = Number(e.cnt) + Number(this.cnt);
          isArrChanche = true;
        }
      });
      if (!isArrChanche) {
        this.ordersProducts.push({
          pId: Number(productId),
          name: this.searchList.name,
          price: this.searchList.price,
          cnt: this.cnt,
          parentUrl: this.searchList.parentUrl,
          url: this.searchList.url,
          article: this.searchArticle,
          saler: "DNS",
          elko: {
            elkoNum: "0",
            elkoPrice: "0"
          },
          gar: "12 мес.",
          imei: "",
          status: "0"
        });
      }

      this.searchList = {};
      this.cnt = 1;
      this.searchArticle = "";
      this.searchPlahaisShow = false;
    },
    addToOrder() {  
      console.log(this.ordersProducts);    


      
        this.ordersProducts.push({
          pId: '0000',
          name: this.addName,
          price: this.addPrice,
          cnt: this.cnt,
          parentUrl: "",
          id: "0",
          url: "",
          saler: "DNS",
          elko: {
            elkoNum: "0",
            elkoPrice: "0"
          },
          gar: "12 мес.",
          imei: "",
          status: "0"
        });
     

      this.searchList = {};
      this.cnt = 1;
      this.addName = "";
      this.addPrice = "";
    },

    async searchProduct() {
      this.searchList = await api.methods.PRODUCT_search({
        article: this.searchArticle.trim(),
      });
      if (!Helper.methods.isObject(this.searchList)) {
        Helper.methods.warning("Товар не найден", "error");
      } else {
        this.searchPlahaisShow = true;
      }
    },

    async list() {
      let response = await api.methods.ORDER_get({
        id: this.$route.params.id,
      });
      this.userName = response.user.name;
      this.userDelivery = response.user.delivery;
      this.userId = response.user.id;
      this.userPhone = response.user.phone;
      this.userRating = response.userRating;
      this.ordersProducts = response.details;
      this.orderId = response.id;
      this.telega = response.telega;
      this.orderData = response.dat;
      this.trackNum = response.track;
      this.orderTime = response.tim;
      this.orderStatus = response.status;
      this.userComment = response.userComment;
      this.managerComment = response.managerComment;
      this.payment = response.payment;
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.list();
    }
  },
  async created() {},
  components: {
    Header,
    DropDown,
  },
};
</script>